'use client';

import classnames from 'classnames';
import type { ReactNode } from 'react';
import React, { useState } from 'react';

export interface TooltipProps {
  title?: string;
  content: string;
  children?: ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  isCorner?: boolean;
  mouseEnterDelay?: number;
  customClass?: string;
  customDivClass?: string;
  isInlineBlock?: boolean;
  enableHover?: boolean;
}

const Tooltip = ({
  children,
  title,
  content,
  placement = 'top',
  isCorner = false,
  mouseEnterDelay = 100,
  customClass = '',
  customDivClass = '',
  isInlineBlock = true,
  enableHover = true,
}: TooltipProps) => {
  let timeout: ReturnType<typeof setTimeout>;
  let tooltipClass: string = 'w-[260px]';

  const [active, setActive] = useState<boolean>(false);

  const showTip = () => {
    if (enableHover) {
      timeout = setTimeout(() => {
        setActive(true);
      }, mouseEnterDelay);
    }
  };

  const hideTip = () => {
    if (enableHover) {
      clearInterval(timeout);
      setActive(false);
    }
  };

  if (customClass) {
    // remove dynamic construct classname width `w-[${customWidth}px]`
    // according to docs https://tailwindcss.com/docs/content-configuration#dynamic-class-names
    tooltipClass = customClass;
  }

  const tipWrapper = classnames(
    "before:content-[' '] absolute z-50 rounded rounded-md bg-white p-3 before:pointer-events-none before:absolute before:left-2/4 before:ml-[calc(6px*-1)] before:h-0 before:w-0 before:border before:border-[6px] before:border-transparent dark:bg-black",
    {
      'left-2/4 top-full mt-[5px] -translate-x-2/4 shadow-tooltipBottom before:bottom-full before:border-b-white':
        placement === 'bottom',
      'bottom-full left-2/4 mb-[5px] -translate-x-2/4 shadow-tooltipTop before:top-full before:border-t-white ':
        placement === 'top',
      'left-auto right-[calc(100%+15px)] top-1/2 -translate-y-1/2 translate-x-1.5 shadow-tooltipTop before:left-auto before:right-[calc(6px*-2)] before:top-1/2 before:-translate-y-1/2 before:translate-x-0 before:border-l-white before:dark:border-l-white':
        placement === 'left',
      ' left-[calc(100%+12px)] top-1/2 -translate-x-0 -translate-y-1/2 shadow-tooltipTop before:left-[calc(6px*-1)] before:top-1/2 before:-translate-y-1/2 before:translate-x-0 before:border-r-white before:dark:border-r-white':
        placement === 'right',
      'left-[90%]': placement === 'top' && isCorner,
      'left-[22%]': placement === 'bottom' && isCorner,
    }
  );

  const divWrapper = classnames('relative', customDivClass, { 'inline-block': isInlineBlock });

  return (
    <div className={divWrapper} onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && (
        <div className={`${tipWrapper} ${tooltipClass}`}>
          <span
            className="whitespace-no-wrap relative z-10 text-sm font-normal leading-none text-textPrimary dark:text-white"
            dangerouslySetInnerHTML={{ __html: content || '' }}
          />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
