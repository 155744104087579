import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

const timeZone = 'America/North_Dakota/Center';

dayjs.extend(utc);
dayjs.extend(tz); // dependent on utc plugin

export const formatDate = (datetime?: Date | string, format: string = 'MM/DD/YYYY', withTimeZone: boolean = false) => {
  if (!datetime) {
    return '';
  }
  if (!withTimeZone) {
    const [date] = datetime?.toString().split('T') ?? [];
    return dayjs(date).format(format);
  }
  return dayjs(datetime).tz(timeZone, true).format(format);
};

export const formatDateWithCustomDefault = (
  datetime?: Date | string,
  format: string = 'MM/DD/YYYY',
  withTimeZone: boolean = false,
  defaultValue: string = 'N/A'
) => {
  if (!datetime) {
    return defaultValue;
  }
  if (!withTimeZone) {
    const [date] = datetime?.toString().split('T') ?? [];
    return dayjs(date).format(format);
  }
  return dayjs(datetime).tz(timeZone, true).format(format);
};

export const isPastDate = (datetime?: Date | string) => {
  const [date] = datetime?.toString().split('T') ?? [];
  return dayjs().isAfter(dayjs(date), 'D');
};

export const validateDate = (date?: Date | string) => {
  return dayjs(date).isValid();
};
