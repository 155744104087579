'use client';

import classNames from 'classnames';

// enum Level_Type {
//   h1 = 'H1',
//   h2 = 'H2',
//   h3 = 'H3',
//   h4 = 'H4',
//   h5 = 'H5'
// }

interface IProps {
  text?: string | number;
  type?: string;
  weight?: string;
  size?: string;
  level?: number;
  containerWidth?: number;
  customClass?: string;
}

const Text = ({ text, type = 'default', weight = 'normal', size = 'base', customClass }: IProps) => {
  const renderType = (type: string) => {
    switch (type) {
      case 'primary':
        return 'text-textBlue';
      case 'success':
        return 'text-textSuccess';
      case 'warning':
        return 'text-textWarning';
      case 'error':
        return 'text-danger';
      case 'secondary':
        return 'text-textSecondary';
      case 'custom':
        return '';
      case 'default':
        return 'text-textPrimary dark:text-darkTextPrimary';
    }
  };

  const renderWeight = (weight: string) => {
    switch (weight) {
      case 'light':
        return 'font-light';
      case 'medium':
        return 'font-medium';
      case 'semibold':
        return 'font-semibold';
      case 'bold':
        return 'font-bold';
      case 'extrabold':
        return 'font-extrabold';
      case 'normal':
        return 'font-normal';
    }
  };

  const renderSize = (size: string) => {
    switch (size) {
      case 'xs':
        return 'text-xs'; // 12px
      case 'sm':
        return 'text-sm'; // 14px
      case 'lg':
        return 'text-lg'; // 18px
      case 'xl':
        return 'text-xl'; // 20px
      case 'base':
        return 'text-base'; // 16px
    }
  };
  const textClassNames = classNames(renderType(type), renderWeight(weight), renderSize(size), customClass);

  return <span className={textClassNames}>{text}</span>;
};

export default Text;
