export const LABELS = {
  reports: {
    hospital: {
      long_name: 'Facility',
      short_name: 'Facility',
      tooltip: '',
    },
    review_date: {
      long_name: 'Review Date',
      short_name: 'Review Date',
      tooltip: '',
    },
    response_date: {
      long_name: 'Responses period ended on',
      short_name: 'Ended on',
      tooltip: '',
    },
    future_response_date: {
      long_name: 'Responses must be submitted by',
      short_name: 'Responses must be submitted by',
      tooltip: '',
    },
    response_date_open: {
      long_name: 'Response date is open',
      short_name: 'Response date is open',
      tooltip: '',
    },
    filter: {
      label: {
        long_name: 'Filter Records',
        short_name: 'Filter',
        tooltip: '',
      },
      search: {
        long_name: 'Search',
        short_name: 'Search',
        tooltip: '',
        placeholder: {
          long_name: 'Search for description, NDC, item number, or vendor ',
          short_name: 'Search',
          tooltip: '',
        },
        dropdown: {
          label: {
            long_name: 'Filter',
            short_name: 'Filter',
            tooltip: '',
          },
          selection_purchased: {
            long_name: 'Purchased Item',
            short_name: 'Purchased Item',
            tooltip: '',
          },
          selection_conversion: {
            long_name: 'Conversion Recommendation',
            short_name: 'Conversion',
            tooltip: '',
          },
        },
      },
    },
    total_records: {
      long_name: 'Total Records',
      short_name: 'Total',
      tooltip: '',
    },
    total_potential_savings: {
      long_name: 'Total Potential Savings',
      short_name: 'Total Potential Savings',
      tooltip: '',
    },
    propriety: {
      long_name: 'Proprietary and Confidential - Property of S/T Health Group',
    },
    account_info: {
      long_name: 'Account Information',
      short_name: 'Account Info',
    },
  },
  purchases: {
    column_header: 'Purchased Item',
    ndc: {
      long_name: 'NDC',
      short_name: 'NDC',
      tooltip: '',
    },
    item_num: {
      long_name: 'Item Number',
      short_name: 'Item #',
      tooltip: '',
    },
    total_qty: {
      long_name: 'Purch Qty (Month)',
      short_name: 'Purch Qty',
      tooltip: 'Combined purchased quantity of this NDC across all account types.',
    },
    amu: {
      long_name: 'AMU',
      short_name: 'AMU',
      tooltip: 'Avg Monthly Usage',
    },
    vendor: {
      long_name: 'Vendor',
      short_name: 'Vendor',
      tooltip: '',
    },
    description: {
      long_name: 'Description',
      short_name: 'Description',
      tooltip: '',
    },
    wholesaler: {
      long_name: 'Wholesaler',
      short_name: 'Wholesaler',
      tooltip: '',
    },
    start_date: {
      long_name: 'Purch Start Date',
      short_name: 'Start',
      tooltip: '',
    },
    end_date: {
      long_name: 'Purch End Date',
      short_name: 'End',
      tooltip: '',
    },
    avail_qty: {
      long_name: 'Qty Available',
      short_name: 'Qty Available',
      tooltip: '',
    },
    account_type: {
      long_name: 'Acct Type',
      short_name: 'Type',
      tooltip: '',
    },
    gpo_account_type: {
      long_name: 'GPO Account Type',
      short_name: 'GPO Type',
      tooltip: '',
    },
    phs_account_type: {
      long_name: 'PHS Account Type',
      short_name: 'PHS Type',
      tooltip: '',
    },
    wac_account_type: {
      long_name: 'WAC Account Type',
      short_name: 'WAC Type',
      tooltip: '',
    },
    gpo_utilization: {
      long_name: 'GPO Util %',
      short_name: 'Util %',
      tooltip: '',
    },
    phs_utilization: {
      long_name: 'PHS Util %',
      short_name: 'Util %',
      tooltip: '',
    },
    wac_utilization: {
      long_name: 'WAC Util %',
      short_name: 'Util %',
      tooltip: '',
    },
    utilization: {
      long_name: 'Generic Util %',
      short_name: 'Util %',
      tooltip:
        'GPO/PHS/WAC percentage split based on the quantities of all NDCs purchased within a generic entity group',
    },
    cost: {
      long_name: 'Cost',
      short_name: 'Cost',
      tooltip: '',
    },
    gpo_cost: {
      long_name: 'GPO Cost',
      short_name: 'Cost',
      tooltip: '',
    },
    phs_cost: {
      long_name: 'PHS Cost',
      short_name: 'Cost',
      tooltip: '',
    },
    wac_cost: {
      long_name: 'WAC Cost',
      short_name: 'Cost',
      tooltip: '',
    },
    adj_ext_cost: {
      long_name: 'Adj Extension Cost',
      short_name: 'Adj Ext Cost',
      tooltip:
        "Extended cost based on a typical month's GPO/PHS/WAC utilization percentages. Calculated as (Unit Price * (Total Quantity * Utilization % by Account Type))",
    },
    price_source: {
      long_name: 'Price Source',
      short_name: 'Source',
      tooltip: '',
    },
    gpo_adj_ext_cost: {
      long_name: 'GPO Adj Ext Cost',
      short_name: 'GPO Adj Ext Cost',
      tooltip: '',
    },
    gpo_price_source: {
      long_name: 'GPO Price Source',
      short_name: 'GPO Source',
      tooltip: '',
    },
    phs_adj_ext_cost: {
      long_name: 'PHS Adj Ext Cost',
      short_name: 'PHS Adj Ext Cost',
      tooltip: '',
    },
    phs_price_source: {
      long_name: 'PHS Price Source',
      short_name: 'PHS Source',
      tooltip: '',
    },
    wac_adj_ext_cost: {
      long_name: 'WAC Adj Ext Cost',
      short_name: 'WAC Adj Ext Cost',
      tooltip: '',
    },
    wac_price_source: {
      long_name: 'WAC Price Source',
      short_name: 'WAC Source',
      tooltip: '',
    },
    blended_ndc_cost: {
      long_name: 'Blended NDC Cost',
      short_name: 'Blended Cost',
      tooltip:
        "Blended NDC Cost is a combined price based on a typical month's utilization patterns. Calculated as the sum of (Avg Price * Annual Util %) for each account type (GPO/PHS/WAC)",
    },
    gpo: {
      long_name: 'GPO',
      short_name: 'GPO',
      tooltip: '',
    },
    phs: {
      long_name: 'PHS',
      short_name: 'PHS',
      tooltip: '',
    },
    wac: {
      long_name: 'WAC',
      short_name: 'WAC',
      tooltip: '',
    },
    orange_book_rating: {
      long_name: 'Orange Book Rating',
      short_name: 'Orange Book Rating',
      tooltip: '',
    },
    ndc_purch: {
      long_name: 'NDC Purch',
      short_name: 'NDC Purch',
      tooltip: '',
    },
    qty_month: {
      long_name: 'Qty (Month)',
      short_name: 'Qty (Month)',
      tooltip: '',
    },
    ndc_last: {
      long_name: 'NDC Last',
      short_name: 'NDC Last',
      tooltip: '',
    },
    purch_date: {
      long_name: 'Purch Date',
      short_name: 'Purch Date',
      tooltip: '',
    },
    ndc_purch_qty: {
      long_name: 'NDC Purch Qty (Month)',
      short_name: 'NDC Purch Qty (Month)',
      tooltip: '',
    },
    ndc_last_purch_date: {
      long_name: 'NDC Last Purch Date',
      short_name: 'NDC Last Purch Date',
      tooltip: '',
    },
    table_generic: {
      long_name: 'Generic',
      short_name: 'Generic',
      tooltip: '',
    },
    util_percentage: {
      long_name: 'Util %',
      short_name: 'Util %',
      tooltip: '',
    },
  },

  conversions: {
    column_header: 'Conversion Recommendation',
    ndc: {
      long_name: 'NDC',
      short_name: 'NDC',
      tooltip: '',
    },
    item_num: {
      long_name: 'Item Number',
      short_name: 'Item #',
      tooltip: '',
    },
    total_qty: {
      long_name: 'Total Quantity',
      short_name: 'Total Qty',
      tooltip: '',
    },
    vendor: {
      long_name: 'Vendor',
      short_name: 'Vendor',
      tooltip: '',
    },
    description: {
      long_name: 'Description',
      short_name: 'Description',
      tooltip: '',
    },
    wholesaler: {
      long_name: 'Wholesaler',
      short_name: 'Wholesaler',
      tooltip: '',
    },
    dc_code: {
      long_name: 'DC Code',
      short_name: 'DC',
      tooltip:
        'Distribution Center Code (based on STHG client purchases in previous month<br/><br/> A1: Purchased at primary wholesaler and DC<br/>A2: Purchased at primary wholesaler but alternate DC<br/>B: Purchased at any wholesaler within STHG clients<br/>C: No purchases seen by any STHG clients',
    },
    dc_code_value: {
      long_name: '',
      short_name: '',
      tooltip: 'dynamic results based on backend data field [Conv_DC_HX_Note]',
    },
    dc_availability: {
      long_name: 'DC Availability',
      short_name: 'DC Availability',
      tooltip: '',
    },
    dist_center_history: {
      long_name: 'Distribution Center History',
      short_name: 'Distribution Center History',
      tooltip: '',
    },
    last_wac_purchase: {
      long_name: 'Last WAC Purchase',
      short_name: 'Last WAC Purch',
      tooltip: '',
    },
    multiplier: {
      long_name: 'Pack Size Multiplier',
      short_name: 'Multiplier',
      tooltip: '',
    },
    utilization: {
      long_name: 'Utilization % by Class',
      short_name: 'Utilization %',
      tooltip: '',
    },
    cost: {
      long_name: 'Cost',
      short_name: 'Cost',
      tooltip: '',
    },
    gpo_cost: {
      long_name: 'GPO Cost',
      short_name: 'GPO Cost',
      tooltip: '',
    },
    gpo_adj_unit_price: {
      long_name: 'GPO Adj Extension Cost',
      short_name: 'GPO Ext Cost',
      tooltip: '',
    },
    gpo_price_source: {
      long_name: 'GPO Price Source',
      short_name: 'GPO Price Source',
      tooltip: '',
    },
    phs_cost: {
      long_name: 'PHS Unit Price',
      short_name: 'PHS Price',
      tooltip: '',
    },
    phs_unit_cost: {
      long_name: 'PHS Cost',
      short_name: 'PHS Cost',
      tooltip: '',
    },
    phs_adj_unit_price: {
      long_name: 'PHS Adj Extension Cost',
      short_name: 'PHS Ext Cost',
      tooltip: '',
    },
    phs_price_source: {
      long_name: 'PHS Price Source',
      short_name: 'PHS Price Source',
      tooltip: '',
    },
    wac_cost: {
      long_name: 'WAC Unit Price',
      short_name: 'WAC Price',
      tooltip: '',
    },
    wac_unit_cost: {
      long_name: 'WAC Cost',
      short_name: 'WAC Cost',
      tooltip: '',
    },
    wac_adj_unit_price: {
      long_name: 'WAC Adj Extension Cost',
      short_name: 'WAC Ext Cost',
      tooltip: '',
    },
    wac_price_source: {
      long_name: 'WAC Price Source',
      short_name: 'WAC Price Source',
      tooltip: '',
    },
    adj_ext_cost: {
      long_name: 'Adj Extension Cost',
      short_name: 'Adj Ext Cost',
      tooltip:
        "Extended cost based on a typical month's GPO/PHS/WAC utilization percentages. Calculated as (Adj Unit Price * (Total Quantity * Utilization % by Account Type))",
    },
    price_source: {
      long_name: 'Price Source',
      short_name: 'Source',
      tooltip: '',
    },
    blended_ndc_cost: {
      long_name: 'Blended NDC Cost',
      short_name: 'Blended Cost',
      tooltip:
        "Blended NDC Cost is a combined price based on a typical month's utilization patterns. Calculated as the sum of (Avg Price * Annual Util %) for each account type (GPO/PHS/WAC)",
    },
    potential_savings: {
      long_name: 'Potential Savings',
      short_name: 'Pot Savings',
      tooltip: '',
    },
    ranking: {
      long_name: 'Conversion Ranking',
      short_name: 'Ranking',
      tooltip: '',
    },
    block_reason: {
      long_name: 'Block Reason',
      short_name: 'Reason',
      tooltip: '',
    },
    orange_book_rating: {
      long_name: 'Orange Book Rating',
      short_name: 'Orange Book Rating',
      tooltip: '',
    },
    ndc_purch: {
      long_name: 'NDC Purch',
      short_name: 'NDC Purch',
      tooltip: '',
    },
    qty_month: {
      long_name: 'Qty (Month)',
      short_name: 'Qty (Month)',
      tooltip: '',
    },
    ndc_last: {
      long_name: 'NDC Last',
      short_name: 'NDC Last',
      tooltip: '',
    },
    purch_date: {
      long_name: 'Purch Date',
      short_name: 'Purch Date',
      tooltip: '',
    },
    ndc_purch_qty: {
      long_name: 'NDC Purch Qty (Month)',
      short_name: 'NDC Purch Qty (Month)',
      tooltip: '',
    },
    ndc_last_purch_date: {
      long_name: 'NDC Last Purch Date',
      short_name: 'NDC Last Purch Date',
      tooltip: '',
    },
  },
  pharmacy_response: {
    column_header: 'Pharmacy Response',
    current_comment: {
      long_name: 'Current Status/Comment',
    },
    choose_status: {
      long_name: 'Choose current status',
    },
    comment_required: {
      long_name: 'Comment is required.',
    },
    carried_forward: {
      long_name: 'Carried Forward Status/Comment',
    },
    comment_placeholder: {
      long_name: 'Add comment...',
    },
    comment_history: {
      long_name: 'Comment History',
    },
    alternate_items: {
      long_name: 'Alternate Items',
    },
    save_pending: {
      long_name: 'Saving...',
    },
    save_success: {
      long_name: 'Comment saved',
    },
    save_failed: {
      long_name: 'Failed to save comment',
    },
    comments: {
      long_name: 'Comments',
      short_name: 'Comments',
      tooltip: '',
    },
    date: {
      long_name: 'Date',
      short_name: 'Date',
      tooltip: '',
    },
    comment_sort_text: {
      long_name: 'Sorted by Newest to Oldest',
      short_name: 'Sorted by Newest to Oldest',
      tooltip: '',
    },
    comment: {
      long_name: 'Comment',
      short_name: 'Comment',
      tooltip: '',
    },
    more_options: {
      long_name: 'More Options',
      short_name: 'Options',
      tooltip: '',
    },
    status: {
      long_name: 'Status',
      short_name: 'Status',
      tooltip: '',
    },
  },
  common: {
    hash_code: {
      long_name: 'Hash code not found.',
      short_name: 'Hash code not found.',
      tooltip: '',
    },
    dop_comments: {
      long_name: 'DOP COMMENTS',
      short_name: 'DOP COMMENTS',
      tooltip: '',
    },
    errors: {
      general: {
        header: {
          long_name: 'Something went wrong',
          short_name: 'Something went wrong',
          tooltip: '',
        },
        message: {
          long_name: '<p>We were unable to connect to the server. </p><p>Please check your internet or try again.</p>',
          short_name: '<p>We were unable to connect to the server. </p><p>Please check your internet or try again.</p>',
          tooltip: '',
        },
      },
      invalid_url: {
        header: {
          long_name: 'Invalid URL',
          short_name: 'Invalid URL',
          tooltip: '',
        },
        message: {
          long_name: 'Please make sure that <code>?code=</code> is entered in the URL.',
          short_name: 'Please make sure that <code>?code=</code> is entered in the URL.',
          tooltip: '',
        },
      },
      not_found: {
        header: {
          long_name: 'No report found',
          short_name: 'No report found',
          tooltip: '',
        },
        message: {
          long_name: '<p>The requested report was not found.</p> <p>Please make sure that the code is correct.</p>',
          short_name: '<p>The requested report was not found.</p> <p>Please make sure that the code is correct.</p>',
          tooltip: '',
        },
      },
      no_items: {
        header: {
          long_name: 'No items',
          short_name: 'No items',
          tooltip: '',
        },
        message: {
          long_name: '<p>No items were found for this report.</p>',
          short_name: '<p>No items were found for this report.</p>',
          tooltip: '',
        },
      },
      no_filter_result: {
        header: {
          long_name: 'No items',
          short_name: 'No items',
          tooltip: '',
        },
        message: {
          long_name: '<p>No items found from the selected filter. Please check if filters are correct.</p>',
          short_name: '<p>No items found from the selected filter. Please check if filters are correct.</p>',
          tooltip: '',
        },
      },
    },
    toasts: {
      failed: {
        long_name: (action: string, model: string) => `Having trouble ${action} ${model}. Please try again later.`,
      },
    },
    adjusted_extended_cost: {
      long_name: 'Adjusted Extended Cost',
      short_name: 'Adj Extended Cost',
      tooltip: '',
    },
    reload_page: {
      long_name: 'Reload Page',
      short_name: 'Reload Page',
      tooltip: '',
    },
    loading_records: {
      long_name: 'Loading records',
    },
    dark_mode: {
      long_name: 'Dark Mode',
      short_name: 'Dark Mode',
    },
    item_info: {
      long_name: 'Item Information',
      short_name: 'Item Info',
    },
    table: {
      long_name: 'Table',
      short_name: 'Table',
    },
    retrieving_update_qty_avail: {
      long_name: 'Retrieving qty available data...',
      short_name: 'Retrieving qty available data...',
    },
    success_update_qty_avail: {
      long_name: 'Data synced',
      short_name: 'Data synced',
    },
  },
  excel: {
    export_pending: {
      long_name: 'Preparing excel file...',
      short_name: 'Preparing excel file...',
      tooltip: '',
    },
    export_success: {
      long_name: 'Excel file is ready for viewing',
      short_name: 'Excel file is ready for viewing',
      tooltip: '',
    },
    export_failed: {
      long_name: 'Something went wrong...',
      short_name: 'Something went wrong...',
      tooltip: '',
    },
    filename: {
      long_name: 'CCRx Detail Report',
      short_name: 'CCRx Detail Report',
      tooltip: '',
    },
    sheetname: {
      long_name: 'Hosp - Conversion Report',
      short_name: 'Hosp - Conversion Report',
      tooltip: '',
    },
    title: {
      long_name: 'Cost-Contain Rx (CCRx) Report',
      short_name: '',
      tooltip: '',
    },
    wholesaler: {
      long_name: 'WHOLESALER',
      short_name: 'WHLS',
      tooltip: '',
    },
    dc_code: {
      long_name: 'Distribution Center (DC) Codes',
      short_name: 'Distribution Center (DC) Codes',
      tooltip: '',
    },
    dc_code_a: {
      long_name: 'A1 - Purchased at primary wholesaler and DC',
      short_name: 'A1 - Purchased at primary wholesaler and DC',
      tooltip: '',
    },
    dc_code_b: {
      long_name: 'A2 - Purchased at primary wholesaler but alternate DC',
      short_name: 'A2 - Purchased at primary wholesaler but alternate DC',
      tooltip: '',
    },
    dc_code_c: {
      long_name: 'B - Purchase at any wholesaler within STHG clients',
      short_name: 'B - Purchase at any wholesaler within STHG clients',
      tooltip: '',
    },
    dc_code_d: {
      long_name: 'C - No purchases seen by any STHG clients',
      short_name: 'C - No purchases seen by any STHG clients',
      tooltip: '',
    },

    AS: {
      long_name: 'Wholesaler AutoSub',
      short_name: 'AS',
      tooltip: '',
    },
    HP: {
      long_name: 'Hospital or Pharmacy Preference',
      short_name: 'HP',
      tooltip: '',
    },
    INS: {
      long_name: 'Insurance only covers specific NDC',
      short_name: 'INS',
      tooltip: '',
    },
    NS: {
      long_name: 'Not available at wholesaler',
      short_name: 'NS',
      tooltip: '',
    },
    OK: {
      long_name: 'Will make conversion',
      short_name: 'OK',
      tooltip: '',
    },
    OT: {
      long_name: 'Other: Give Details',
      short_name: 'OT',
      tooltip: '',
    },
    total_qty: {
      long_name: 'PURCH QTY: Combined purchased quantity of this NDC across all account types',
      short_name: 'PURCH QTY: Combined purchased quantity of this NDC across all account types',
      tooltip: '',
    },
    annual_util: {
      long_name:
        "UTIL% by GPID: GPO/PHS/WAC percentage split based on quantities of all NDC's purchased with a generic entity group over a specified time (usually 12 months)",
      short_name:
        "UTIL% by GPID: GPO/PHS/WAC percentage split based on quantities of all NDC's purchased with a generic entity group over a specified time (usually 12 months)",
      tooltip: '',
    },
    avg_price: {
      long_name:
        'COST: Average of unit price paid on the GPO, PHS, or WAC accounts during the review. If not purchased, the bid book price (or WAC) is substituted',
      short_name:
        'COST: Average of unit price paid on the GPO, PHS, or WAC accounts during the review. If not purchased, the bid book price (or WAC) is substituted',
      tooltip: '',
    },
    adj_ext: {
      long_name:
        "ADJ EXT COST: Extended cost based on Avg Price and a typical month's GPO/PHS/WAC utilization patterns. Calculated as (Avg Price * (Total Qty * Annual Util %))",
      short_name:
        "ADJ EXT COST: Extended cost based on Avg Price and a typical month's GPO/PHS/WAC utilization patterns. Calculated as (Avg Price * (Total Qty * Annual Util %))",
      tooltip: '',
    },
    adj_ext_bid: {
      long_name:
        'BLENDED NDC COST: a combined price based on a typical month’s utilization patterns. Calculated as the sum of (Avg Price * Annual Util %) for each account type (GPO/PHS/WAC)',
      short_name:
        'BLENDED NDC COST: a combined price based on a typical month’s utilization patterns. Calculated as the sum of (Avg Price * Annual Util %) for each account type (GPO/PHS/WAC)',
      tooltip: '',
    },
  },
};
