'use client';

import type { ButtonHTMLAttributes } from 'react';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  disabled?: boolean;
  handleClick?: () => void;
  btnClass?: string;
  children?: React.ReactNode;
}

const Button = ({ label = 'Button text', disabled, handleClick, btnClass = '', children, ...props }: IProps) => {
  return (
    <button onClick={handleClick} className={`btn ${btnClass}`} disabled={disabled} {...props}>
      {children || label}
    </button>
  );
};

export default Button;
