import { formatDate } from '@/utils/date';

import metadata from '../../../public/metadata.json';
import Text from '../common/Text';
import Tooltip from '../common/tooltip';

const VersionTooltip = () => (
  <div className="absolute bottom-6 right-6">
    <Tooltip
      isCorner
      placement="left"
      content={`Last Released: ${formatDate(metadata.releaseDate, 'MM/DD/YYYY HH:mm', true)}`}
    >
      <Text text={metadata.version} />
    </Tooltip>
  </div>
);

export default VersionTooltip;
