'use client';

import Image from 'next/image';

import Button from '@/components/common/Button';
import VersionTooltip from '@/components/VersionTooltip';
import { LABELS } from '@/utils/label';

const imageSizes: {
  [key: string]: {
    width: number;
    height: number;
  };
} = {
  general: {
    width: 419,
    height: 293,
  },
  not_found: {
    width: 300,
    height: 240,
  },
  no_items: {
    width: 270,
    height: 290,
  },
  no_filter_result: {
    width: 270,
    height: 290,
  },
};

export default function Error({ errorType = 'general' }: { errorType: string }) {
  const errorMessage = LABELS.common.errors[errorType];
  const isGeneral = errorType === 'general';
  const imageType = ['not_found', 'invalid_url'].includes(errorType) ? 'not_found' : errorType;
  const imageProps = {
    src: `/error-${imageType}.png`,
    width: imageSizes?.[imageType]?.width,
    height: imageSizes?.[imageType]?.height,
  };
  const reloadPage = () => (window.location.href = window.location.href);

  return (
    <div className="grid justify-center justify-items-center py-[100px]">
      <Image
        src={imageProps.src}
        alt={errorMessage.header.long_name}
        width={imageProps.width}
        height={imageProps.height}
      />
      <h2 className="my-4 text-xl font-bold">{errorMessage.header.long_name}</h2>
      <div
        className="text-gray-900 mb-4 w-[410px] text-center text-base dark:text-darkTextPrimary"
        dangerouslySetInnerHTML={{ __html: errorMessage.message.long_name }}
      />
      {isGeneral && (
        <div>
          <Button className="btn bg-white px-4 py-2 text-sm text-black" handleClick={reloadPage}>
            {LABELS.common.reload_page.long_name}
          </Button>
        </div>
      )}

      <VersionTooltip />
    </div>
  );
}
